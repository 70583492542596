<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="tasks"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              @search="getData"
              @change="filterData"
              @loaded="onFilteringPanelLoad"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>

        <b-row v-show="mode === 'table' && isDataVisible">
          <b-col>
            <tasks-table
              ref="tasks"
              :rows-per-page="20"
              :autoload="false"
              :selectable-rows="true"
              @loaded="onTasksLoad"
              :filter-by-column="true"
            />
          </b-col>
        </b-row>
        <b-row v-if="mode === 'kanban' && isDataVisible">
          <b-col>
            <kanban ref="tasks" @loaded="onTasksLoad" />
          </b-col>
        </b-row>
        <b-row v-if="mode === 'gantt' && isDataVisible">
          <b-col>
            <gantt ref="tasks" @loaded="onTasksLoad" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import TasksTable from "@/views/ProjectManagement/TasksTable.vue";
import Kanban from "@/views/ProjectManagement/Kanban.vue";
import Gantt from "@/views/ProjectManagement/Gantt.vue";

import { mapState } from "vuex";

export default {
  name: "Tasks",
  props: {
    filter: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: ""
    }
  },
  components: {
    FilteringPanel,
    TasksTable,
    Kanban,
    Gantt
  },
  data: function() {
    return {
      isLoading: false,
      isDataVisible: true,
      rawData: [],
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "input",
            title: "Task Name",
            name: "task_name",
            dataType: "string",
            tooltip: "Task name"
          },

          {
            type: "select",
            dataType: "string",
            title: "Creator",
            name: "creator",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [],
            tooltip: "Creator",
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Assigned To",
            name: "assigned_to",
            trackby: "id",
            label: "label",
            options: [],
            tooltip: "Assigned to",
            selected: {},
            group_values: "group_values",
            group_label: "group_label"
          },
          {
            type: "select",
            dataType: "string",
            title: "Status",
            name: "status",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            tooltip: "Status",
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Project Name",
            name: "project",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            tooltip: "Project",
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Priority",
            name: "priority",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            tooltip: "Priority",
            selected: {}
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "7 years",
            title: "Start Date",
            name: "start-date",
            options: [],
            tooltip: "Start date"
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "7 years",
            title: "Due Date",
            name: "due-date",
            options: [],
            tooltip: "Due date"
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "7 years",
            title: "Updated",
            name: "updated-date",
            options: [],
            tooltip: "Updated"
          }
        ]
      },
      groups: [],
      assignedTo: {
        options: []
      },
      taskPriorities: {
        options: []
      },
      recurringTypes: {
        options: []
      },
      statuses: {
        options: []
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),

  async mounted() {},
  methods: {
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        let users = await this.$api.get("users");

        this.filteringPanel.filters.find(
          f => f.name === "creator"
        ).options = users.map(u => ({
          id: u.id,
          label: u.full_name
        }));

        let groups = await this.$api.get("groups");

        self.groups = groups;

        self.assignedTo.options = [
          {
            group_label: "Groups",
            group_values: groups.map(u => ({
              id: u.groupid.toString(),
              label: u.groupname.trim()
            }))
          },
          {
            group_label: "Users",
            group_values: users.map(u => ({
              id: u.id.toString(),
              label: u.full_name.trim()
            }))
          }
        ];

        self.filteringPanel.filters.find(
          f => f.name === "assigned_to"
        ).options = self.assignedTo.options;
      };

      const statuses = async () => {
        let statuses = await this.$api.get("tasks/statuses");

        this.filteringPanel.filters.find(
          f => f.name === "status"
        ).options = statuses.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const priorities = async () => {
        let priorities = await this.$api.get("tasks/priorities");

        this.filteringPanel.filters.find(
          f => f.name === "priority"
        ).options = priorities.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const projects = async () => {
        let projects = await this.$api.get("dictionaries/projects");

        this.filteringPanel.filters.find(
          f => f.name === "project"
        ).options = projects.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      Promise.all([users(), statuses(), priorities(), projects()]).then(
        () => (this.filteringPanel.loaded = true)
      );
    },
    onFilteringPanelStateChange() {
      this.isDataVisible = !this.$refs.filteringPanel.expressionBuilder.visible;
    },

    //start loading tasks when filtered panel is loaded
    onFilteringPanelLoad() {
      this.$refs["tasks"].getData();
    },
    onCustomFilterSelected(e) {
      if (e.data) this.onSelectedExpression(e);
    },
    //apply filters to loaded data
    onTasksLoad() {
      if (this.$refs["filteringPanel"].panelState.isCustomFiltersBar) {
        if (!this.$refs["filteringPanel"].selected["custom-filter"]) return;

        let e = this.$refs["filteringPanel"].selected["custom-filter"];

        if (e.data) this.onSelectedExpression(e);
      } else {
        this.filterData(this.$refs["filteringPanel"].selected);
      }
    },
    async onSelectedExpression(e) {
      //if selected expression is empty then apply usual filters
      if (!e || !e.data) {
        this.filterData(this.filteringPanel.selected);
        return;
      }

      let rawData = this.$refs["tasks"].rawData;

      if (rawData.length === 0) {
        await this.$form.msgBoxOk("No data available!");
        return;
      }
      let expressions = JSON.parse(e.data);

      if (!expressions) {
        self.$form.makeToastError("Invalid expression");
        return;
      }

      this.$refs["tasks"].loading(true);

      let filteredData = this.$helpers.applyExpressions2Dataset(
        expressions,
        rawData
      );

      this.$refs["tasks"].dataTable.dataSet = filteredData;

      this.presetFilteredData = filteredData;

      this.$refs["tasks"].loading(false);
    },
    filterData: function(e) {
      this.filteringPanel.selected = e;

      if (!this.$refs["tasks"].rawData.length) return;

      const search = () => {
        try {
          let filteredData = this.$refs["tasks"].rawData;

          //if preset is selected then use filtered dataset
          if (
            this.$refs["filteringPanel"].panelState.isCustomFiltersBar &&
            this.$refs["filteringPanel"].selected["custom-filter"]
          )
            filteredData = this.presetFilteredData;

          filteredData = filteredData.filter(i =>
            this.filteringPanel.selected.task_name &&
            this.filteringPanel.selected.task_name !== ""
              ? i["Task Name"]
                  .toLowerCase()
                  .includes(
                    this.filteringPanel.selected.task_name.toLowerCase()
                  )
              : true
          );

          if (this.filteringPanel.selected != undefined) {
            filteredData = filteredData.filter(i =>
              this.filteringPanel.selected.creator
                ? i["Creator"].includes(
                    this.filteringPanel.selected.creator.label
                  )
                : true
            );

            filteredData = filteredData.filter(i =>
              this.filteringPanel.selected.assigned_to
                ? i["Assigned To"].includes(
                    this.filteringPanel.selected.assigned_to
                      ? this.filteringPanel.selected.assigned_to.label
                      : true
                  )
                : true
            );

            filteredData = filteredData.filter(i =>
              this.filteringPanel.selected.status &&
              this.filteringPanel.selected.status.length > 0
                ? this.filteringPanel.selected.status.find(
                    s => s.label === i["Status"]
                  )
                : true
            );

            filteredData = filteredData.filter(i =>
              this.filteringPanel.selected.project &&
              this.filteringPanel.selected.project.length > 0
                ? this.filteringPanel.selected.project.find(
                    s => s.label === i["Project Name"]
                  )
                : true
            );

            filteredData = filteredData.filter(i =>
              this.filteringPanel.selected.priority &&
              this.filteringPanel.selected.priority.length > 0
                ? this.filteringPanel.selected.priority.find(
                    s => s.label === i["Priority"]
                  )
                : true
            );

            filteredData = filteredData.filter(i =>
              this.filteringPanel.selected["start-date"]
                ? new Date(i["Start Date"]) >=
                    new Date(
                      this.filteringPanel.selected["start-date"].startDate
                    ) &&
                  new Date(i["Start Date"]) <=
                    new Date(this.filteringPanel.selected["start-date"].endDate)
                : true
            );

            filteredData = filteredData.filter(i =>
              this.filteringPanel.selected["due-date"]
                ? new Date(i["Due Date"]) >=
                    new Date(
                      this.filteringPanel.selected["due-date"].startDate
                    ) &&
                  new Date(i["Due Date"]) <=
                    new Date(this.filteringPanel.selected["due-date"].endDate)
                : true
            );

            filteredData = filteredData.filter(i => {
              return this.filteringPanel.selected["updated-date"]
                ? new Date(i["Updated"]) >=
                    new Date(
                      this.filteringPanel.selected["updated-date"].startDate
                    ) &&
                    new Date(i["Updated"]) <=
                      new Date(
                        this.filteringPanel.selected["updated-date"].endDate
                      )
                : true;
            });
          }

          this.$refs["tasks"].dataTable.dataSet = filteredData;
        } catch (err) {
          console.log(err);
        }
      };

      setTimeout(() => {
        search();
      }, 500);
    },
    getData: function(e) {
      if (e) this.filteringPanel.selected = e;

      this.$refs["tasks"].getData();
    }
  },
  watch: {
    "dataTable.dataSet": function() {}
  }
};
</script>

<style scoped>
::v-deep .fa-toolbar {
  color: #0000008a;
}

/*
::v-deep table .multiselect {
  width: 190px;
}
*/
/*
.table-responsive {
    overflow-y: visible !important;
    overflow-x: scroll;
}

table {
    overflow: inherit !important;
}
*/

.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 150px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}
</style>
